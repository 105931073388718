
import { defineComponent } from "vue";
import { copyText } from "vue3-clipboard";
import OtherLinks from "@/components/parts/otherLinks.vue";

export default defineComponent({
  components: {
    OtherLinks
  },
  setup() {
    const doCopy = () => {
      copyText(
        "https://type-comic.work",
        undefined,
        (error: any) => {
          if (error) {
            // error
          } else {
            // success
          }
        }
      );
    };

    return {
      doCopy,
    };
  },
});
