export default {
  DEFAULT_TITLE: "タイプコミック - 漫画タイピングゲーム",
  FIREBASE_COLLECTION: {
    COMICS: "comics",
  },
  INPUT_TABLE: {
    あ: ["a"],
    い: ["i"],
    う: ["u", "wu"],
    え: ["e"],
    お: ["o"],
    か: ["ka", "ca"],
    き: ["ki"],
    く: ["ku", "cu"],
    け: ["ke"],
    こ: ["ko", "co"],
    さ: ["sa"],
    し: ["si", "shi"],
    す: ["su"],
    せ: ["se"],
    そ: ["so"],
    た: ["ta"],
    ち: ["ti", "chi"],
    つ: ["tu", "tsu"],
    て: ["te"],
    と: ["to"],
    な: ["na"],
    に: ["ni"],
    ぬ: ["nu"],
    ね: ["ne"],
    の: ["no"],
    は: ["ha"],
    ひ: ["hi"],
    ふ: ["hu", "fu"],
    へ: ["he"],
    ほ: ["ho"],
    ま: ["ma"],
    み: ["mi"],
    む: ["mu"],
    め: ["me"],
    も: ["mo"],
    や: ["ya"],
    ゆ: ["yu"],
    よ: ["yo"],
    ら: ["ra"],
    り: ["ri"],
    る: ["ru"],
    れ: ["re"],
    ろ: ["ro"],
    わ: ["wa"],
    を: ["wo"],
    ん: ["n", "nn", "xn"],
    が: ["ga"],
    ぎ: ["gi"],
    ぐ: ["gu"],
    げ: ["ge"],
    ご: ["go"],
    ざ: ["za"],
    じ: ["ji", "zi"],
    ず: ["zu"],
    ぜ: ["ze"],
    ぞ: ["zo"],
    だ: ["da"],
    ぢ: ["di"],
    づ: ["du"],
    で: ["de"],
    ど: ["do"],
    ば: ["ba"],
    び: ["bi"],
    ぶ: ["bu"],
    べ: ["be"],
    ぼ: ["bo"],
    ぱ: ["pa"],
    ぴ: ["pi"],
    ぷ: ["pu"],
    ぺ: ["pe"],
    ぽ: ["po"],
    きゃ: ["kya", "kilya", "kixya"],
    きぃ: ["kyi", "kili", "kixi", "kilyi", "kixyi"],
    きゅ: ["kyu", "kilyu", "kixyu"],
    きぇ: ["kye", "kile", "kixe", "kilye", "kixye"],
    きょ: ["kyo", "kilyo", "kixyo"],
    ぎゃ: ["gya", "gilya", "gixya"],
    ぎぃ: ["gyi", "gili", "gixi", "gilyi", "gixyi"],
    ぎゅ: ["gyu", "gilyu", "gixyu"],
    ぎぇ: ["gye", "gile", "gixe", "gilye", "gixye"],
    ぎょ: ["gyo", "gilyo", "gixyo"],
    しゃ: ["sya", "sha", "sisya", "sixya"],
    しぃ: ["syi", "sili", "sixi", "silyi", "sixyi"],
    しゅ: ["syu", "shu", "silyu", "sixyu"],
    しぇ: ["she", "sye", "sile", "sixe", "silye", "sixye"],
    しょ: ["syo", "sho", "silyo", "sixyo"],
    じゃ: ["ja", "zya", "jya", "zilya", "zixya", "jilya", "jixya"],
    じぃ: [
      "zyi",
      "jyi",
      "zili",
      "zixi",
      "jili",
      "jixi",
      "zilyi",
      "zixyi",
      "jilyi",
      "jixyi",
    ],
    じゅ: ["ju", "zyu", "jyu", "jilyu", "jixyu", "zilyu", "zixyu"],
    じぇ: [
      "je",
      "zye",
      "jye",
      "jile",
      "jixe",
      "jilye",
      "jixye",
      "zile",
      "zixe",
      "zilye",
      "zixye",
    ],
    じょ: ["jo", "zyo", "jyo", "jilyo", "jixyo", "zilyo", "zixyo"],
    ちゃ: ["tya", "cha", "cya", "tilya", "tixya", "chilya", "chixya"],
    ちぃ: [
      "tyi",
      "cyi",
      "tili",
      "tixi",
      "chili",
      "chixi",
      "tilyi",
      "tixyi",
      "chilyi",
      "chixyi",
    ],
    ちゅ: ["tyu", "chu", "cyu", "tilyu", "tixyu", "chilyu", "chixyu"],
    ちぇ: [
      "tye",
      "che",
      "cye",
      "tile",
      "tixe",
      "tilye",
      "tixye",
      "chile",
      "chixe",
      "chilye",
      "chixye",
    ],
    ちょ: ["tyo", "cho", "cyo", "tilyo", "tixyo", "chilyo", "chixyo"],
    つぁ: ["tsa", "tula", "tsula", "tuxa", "tsuxa"],
    つぃ: [
      "tsi",
      "tuli",
      "tuxi",
      "tsuli",
      "tsuxi",
      "tulyi",
      "tuxyi",
      "tsulyi",
      "tsuxyi",
    ],
    つぇ: [
      "tse",
      "tule",
      "tuxe",
      "tsule",
      "tsuxe",
      "tulye",
      "tuxye",
      "tsulye",
      "tsuxye",
    ],
    つぉ: ["tso", "tulo", "tuxo", "tsulo", "tsuxo"],
    にゃ: ["nya", "nilya", "nixya"],
    にぃ: ["nyi", "nili", "nixi", "nilyi", "nixyi"],
    にゅ: ["nyu", "nilyu", "nixyu"],
    にぇ: ["nye", "nile", "nixe", "nilye", "nixye"],
    にょ: ["nyo", "nilyo", "nixyo"],
    ひゃ: ["hya", "hilya", "hixya"],
    ひぃ: ["hyi", "hili", "hixi", "hilyi", "hixyi"],
    ひゅ: ["hyu", "hilyu", "hixyu"],
    ひぇ: ["hye", "hile", "hixe", "hilye", "hixye"],
    ひょ: ["hyo", "hilyo", "hixyo"],
    ふぁ: ["fa", "fwa", "hula", "huxa", "fula", "fuxa"],
    ふぃ: [
      "fi",
      "fwi",
      "fyi",
      "huli",
      "huxi",
      "fuli",
      "fuxi",
      "hulyi",
      "fulyi",
      "huxyi",
      "fuxyi",
    ],
    ふぅ: ["fwu", "hulu", "huxu", "fulu", "fuxu"],
    ふぇ: [
      "fe",
      "fwe",
      "fye",
      "fule",
      "fuxe",
      "fulye",
      "fuxye",
      "hule",
      "huxe",
      "hulye",
      "huxye",
    ],
    ふぉ: ["fo", "fwo", "fulo", "fuxo", "hulo", "huxo"],
    くぁ: ["qa", "qwa", "kwa", "kula", "kuxa", "cula", "cuxa"],
    くぃ: [
      "qi",
      "qwi",
      "qyi",
      "kuli",
      "kuxi",
      "kulyi",
      "kuxyi",
      "culi",
      "cuxi",
      "culyi",
      "cuxyi",
    ],
    くぅ: ["qu", "qwu", "kulu", "kuxu", "culu", "cuxu"],
    くぇ: [
      "qe",
      "qwe",
      "qye",
      "kule",
      "kuxe",
      "kuxye",
      "kulye",
      "cule",
      "cuxe",
      "culye",
      "cuxye",
    ],
    くぉ: ["qo", "qwo", "kulo", "kuxo", "culo", "cuxo"],
    ぐぁ: ["gya", "gwa", "gula", "guxa"],
    ぐぃ: ["gyi", "guli", "guxi", "gulyi", "guxyi"],
    ぐぅ: ["gyu", "gulu", "guxu"],
    ぐぇ: ["gye", "gule", "guxe", "gulye", "guxye"],
    ぐぉ: ["gyo", "gulo", "guxo"],
    すぁ: ["swa", "sula", "suxa"],
    すぃ: ["swi", "suli", "suxi", "sulyi", "suxyi"],
    すぅ: ["swu", "sulu", "suxu"],
    すぇ: ["swe", "sule", "suxe", "sulye", "suxye"],
    すぉ: ["swo", "sulo", "suxo"],
    てゃ: ["tha", "telya", "texya"],
    てぃ: ["thi", "teli", "texi", "telyi", "texyi"],
    てゅ: ["thu", "texyu", "telyu"],
    てぇ: ["the", "tele", "texe", "telye", "texye"],
    てょ: ["tho", "telyo", "texyo"],
    とぁ: ["twa", "tola", "toxa"],
    とぃ: ["twi", "toli", "toxi", "tolyi", "toxyi"],
    とぅ: ["twu", "tolu", "toxu"],
    とぇ: ["twe", "tole", "toxe", "tolye", "toxye"],
    とぉ: ["two", "tolo", "toxo"],
    ぢゃ: ["dya", "dilya", "dixya"],
    ぢぃ: ["dyi", "dili", "dixi", "dilyi", "dixyi"],
    ぢゅ: ["dyu", "dilyu", "dixyu"],
    ぢぇ: ["dye", "dile", "dixe", "dilye", "dixye"],
    ぢょ: ["dyo", "dilyo", "dixyo"],
    でゃ: ["dha", "delya", "dexya"],
    でぃ: ["dhi", "deli", "dexi", "delyi", "dexyi"],
    でゅ: ["dhu", "delyu", "dexyu"],
    でぇ: ["dhe", "dele", "dexe", "delye", "dexye"],
    でょ: ["dho", "delyo", "dexyo"],
    どぁ: ["dwa", "dola", "doxa"],
    どぃ: ["dwi", "doli", "doxi", "dolyi", "doxyi"],
    どぅ: ["dwu", "dolu", "doxu"],
    どぇ: ["dwe", "dole", "doxe", "dolye", "doxye"],
    どぉ: ["dwo", "dolo", "doxo"],
    びゃ: ["bya", "bilya", "bixya"],
    びぃ: ["byi", "bili", "bixi", "bilyi", "bixyi"],
    びゅ: ["byu", "bilyu", "bixyu"],
    びぇ: ["bye", "bile", "bixe", "bilye", "bixye"],
    びょ: ["byo", "bilyo", "bixyo"],
    ぴゃ: ["pya", "pilya", "pixya"],
    ぴぃ: ["pyi", "pili", "pixi", "pilyi", "pixyi"],
    ぴゅ: ["pyu", "pilyu", "pixyu"],
    ぴぇ: ["pye", "pile", "pixe", "pilye", "pixye"],
    ぴょ: ["pyo", "pilyo", "pixyo"],
    みゃ: ["mya", "milya", "mixya"],
    みぃ: ["myi", "mili", "mixi", "mixyi", "milyi"],
    みゅ: ["myu", "milyu", "mixyu"],
    みぇ: ["mye", "mile", "mixe", "mixye", "milye"],
    みょ: ["myo", "milyo", "mixyo"],
    りゃ: ["rya", "rilya", "rixya"],
    りぃ: ["ryi", "rili", "rixi", "rilyi", "rixyi"],
    りゅ: ["ryu", "rilyu", "rixyu"],
    りぇ: ["rye", "rile", "rixe", "rilye", "rixye"],
    りょ: ["ryo", "rilyo", "rixyo"],
    うぃ: ["wi", "uli", "uxi", "ulyi", "uxyi"],
    うぇ: ["we", "ule", "uxe", "ulye", "uxye"],
    ゔぁ: ["va", "vula", "vuxa"],
    ゔぃ: ["vi", "vuli", "vuxi", "vulyi", "vuxyi"],
    ゔ: ["vu"],
    ゔぇ: ["ve", "vule", "vuxe", "vulye", "vuxye"],
    ゔぉ: ["vo", "vulo", "vuxo"],
    ぁ: ["la", "xa"],
    ぃ: ["li", "xi", "xyi", "lyi"],
    ぅ: ["lu", "xu"],
    ぇ: ["le", "xe", "xye", "lye"],
    ぉ: ["lo", "xo"],
    っ: ["ltu", "xtu", "xtsu", "ltsu"],
    ゃ: ["lya", "xya"],
    ゅ: ["lyu", "xyu"],
    ょ: ["lyo", "xyo"],
  } as { [key: string]: string[] },
};
