<template>
  <div id="other-links">
    <div class="other-link">
      <router-link :to="{ name: 'Home' }" class="other-link-child"
        >home</router-link
      >
    </div>
    <div class="other-link">
      <router-link :to="{ name: 'About' }" class="other-link-child"
        >information</router-link
      >
    </div>
    <div class="other-link">
      <a
        href="https://twitter.com/NagaoTatsuyoshi"
        target="_blank"
        class="other-link-child"
        >about developer</a
      >
    </div>
    <div class="other-link">
      <router-link :to="{ name: 'PrivacyPolicy' }" class="other-link-child"
        >privacy policy</router-link
      >
    </div>
    <div class="other-link">
      <router-link :to="{ name: 'TermsOfUse' }" class="other-link-child"
        >terms of use</router-link
      >
    </div>
  </div>
</template>

<script lang="ts"></script>

<style scoped lang="scss">

#other-links {
    margin-top: 10em;
}

.other-link {
  margin-bottom: 0.25em;

  &-child {
    color: grey;
    font-size: 1em;
    font-weight: bold;
    text-decoration: none;
  }
}
</style>
