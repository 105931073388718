import constants from "@/components/constants";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {},
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: { title: "サイトについて" },
  },
  {
    path: "/typing",
    name: "Typing",
    component: () => import("../views/Typing.vue"),
    meta: { title: "タイピング中" },
  },
  {
    path: "/result",
    name: "Result",
    component: () => import("../views/Result.vue"),
    meta: { title: "結果" },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
    meta: { title: "プライバシーポリシー" },
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: () => import("@/views/TermsOfUse.vue"),
    meta: { title: "利用規約" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  document.title = title
    ? title + " | " + constants.DEFAULT_TITLE
    : constants.DEFAULT_TITLE;

  if (to.name === "Typing" && from.name !== "Home" && from.name !== "Result") {
    next("/");
  } else if (to.name == "Result" && from.name !== "Typing") {
    next("/");
  } else {
    next();
  }
});

export default router;
