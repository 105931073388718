import { createStore } from 'vuex'

export default createStore({
  state: {
    comics: [],
    indexOfComics: 0,
    typeCount: 0,
    missTypeCount: 0,
    db: null as any,
  },
  mutations: {
    setComics(state, comics) {
      state.comics = comics
    },
    setIndexOfComics(state, indexOfComics) {
      state.indexOfComics = indexOfComics
    },
    setTypeCount(state, typeCount) {
      state.typeCount = typeCount
    },
    setMissTypeCount(state, missTypeCount) {
      state.missTypeCount = missTypeCount
    },
    setDb(state, db) {
      state.db = db
    },
  },
  actions: {
  },
  modules: {
  }
})
