import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyA6bj_al6E3citv8SwZCHU4_mRjOooSA4I",
  authDomain: "type-comic.firebaseapp.com",
  projectId: "type-comic",
  storageBucket: "type-comic.appspot.com",
  messagingSenderId: "1027447048042",
  appId: "1:1027447048042:web:39250a2eeb72cc1cf58713",
  measurementId: "G-5CVCWCS4KC",
};

const app = initializeApp(firebaseConfig);
store.commit("setDb", getFirestore());
getAnalytics(app);
createApp(App).use(store).use(router).mount("#app");
